<template>
  <v-container align="center" justify="center">
    <div v-for="(service, serviceId) in services" :key="serviceId">
      <h1
        class="textCenter mb-10 mt-3"
        v-if="currentUser.aiderServices.includes(service)"
      >
        {{
          service == "bfh"
            ? $t("contacts.bfh-contacts")
            : $t("contacts.mok-contacts")
        }}
      </h1>
      <v-row justify="center" align="start">
        <template v-for="(coordinator, index) in coordinatorsList[service]">
          <v-col
            cols="12"
            md="6"
            lg="4"
            :key="index"
            justify="top"
            align="center"
            v-if="currentUser && currentUser.aiderServices.includes(service)"
          >
            <div class="coordinatorItem">
              <v-img
                v-if="coordinator.image"
                :lazy-src="
                  require(`../../assets/contacts/${coordinator.image}`)
                "
                height="200"
                width="200"
                :src="require(`../../assets/contacts/${coordinator.image}`)"
                class="rounded-xl"
              />
              <h3 class="pt-3">
                {{ $t(`contacts.titles.${coordinator.id}`) }} ({{
                  coordinator.serviceName
                }}) -
              </h3>
              <p>
                {{ $t(`contacts.info.${coordinator.id}`) }}
                <b>{{
                  coordinator.phoneNumber ? `- ${coordinator.phoneNumber}` : ""
                }}</b>
              </p>
              <p v-if="coordinator.duties">
                <b>{{ $t("contacts.user-duties") }}: </b
                >{{ $t(coordinator.duties) }}
              </p>
            </div>
          </v-col>
        </template>
      </v-row>
    </div>
    <v-col
      cols="12"
      justify="center"
      align="center"
      class="mb-5 mt-5 specialNumber"
    >
      <h3>{{ $t("contacts.personnel-department") }} - 22535592</h3>
    </v-col>

    <p>
      <b>{{ $t(`contacts.footerInfo.0`) }}</b>
    </p>
    <p v-if="currentUser && currentUser.aiderServices.includes('neighborhood')">
      {{ $t(`contacts.footerInfo.1`) }}
    </p>
    <p v-if="currentUser && currentUser.aiderServices.includes('neighborhood')">
      {{ $t(`contacts.footerInfo.2`) }}
    </p>
    <p v-if="currentUser && currentUser.aiderServices.includes('neighborhood')">
      <i>{{ $t(`contacts.footerInfo.3`) }}</i>
    </p>
  </v-container>
</template>

<script>
import { coordinators } from "@/helpers/constants.js";
export default {
  data() {
    return {
      coordinatorsList: {},
      currentUser: this.$root.currentUserData,
      bfhContacts: [],
      mokContacts: [],
      services: ["neighborhood", "bfh"],
    };
  },
  created() {
    this.coordinatorsList["bfh"] = coordinators.filter(
      (coordinator) => coordinator.service == "bfh"
    );
    this.coordinatorsList["neighborhood"] = coordinators.filter(
      (coordinator) => coordinator.service == "neighborhood"
    );
  },
};
</script>

<style>
.specialNumber {
  border: 2px solid black;
}
.coordinatorItem {
  width: 200px;
}
.textCenter {
  text-align: center;
}
</style>